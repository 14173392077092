<template>
  <div class="specifications">
    <div class="specifications__w">
      <PageHeader :product="product" :prepend="'@Specifications'" />
      <Specifications :specifications="product.specifications" />
    </div>
    <AsideCard :product="product" :navigation-height="navigationHeight" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import PageHeader from "~/modules/product/ui/components/PageHeader.vue";
import AsideCard from "~/modules/product/ui/components/AsideCard.vue";
import Specifications from "~/modules/product/ui/components/Specifications.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

const patternSeo = {
  metaTitle: {
    postfix: () => _T("@Specifications"),
  },
  metaDescription: {
    postfix: () => _T("@online store") + " | GreenVision",
  },
  metaKeywords: {
    postfix: () => _T("@Specifications"),
  },
};

configureProductSeo(props.product, patternSeo);
</script>

<style lang="scss" scoped>
.specifications {
  @include flex-container(row, center, flex-start);
  gap: 44px;

  @include bigMobile {
    gap: 16px;
  }

  &__w {
    width: 100%;
    max-width: 880px;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__btn {
    width: max-content;

    @include font;
    letter-spacing: 0.02em;

    color: var(--color-primary-base);

    transition: color 0.2s;

    &:hover {
      color: var(--color-primary-dark);
    }
  }
}
</style>
